
.form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  min-width: 450px;
  max-width: 450px;
  border-bottom: 3px solid #bbb;
}

.radio-group {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.form-check {
  font-family: 'Segoe UI', serif;
  font-weight: 700;
  font-size: 14px;
  padding: 0px 5px;
  color: #2d3a4e;
}

h3 {
  margin-bottom: 10px;
  margin-top: 0px
}

.error {
  color: #ff1e52;
  font-weight: 500;
}

a {
  color: #5C6BC0;
  text-decoration: none;
  font-weight: 600;
  margin: 15px 3px 0 3px
}

.input-label {
  text-align: left;
  font-size: 13px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.simple-input {
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
  margin-top: 15px;
  font-family: 'Segoe UI';
  background-color: rgba(84, 94, 111, .04);
  border: none;
  box-shadow: inset 0 2px 0 rgb(84 94 111 / 20%);
  font-weight: 600;
  color: #757575;
}

.simple-input:focus {
  color: #555;
  box-shadow: inset 0 2px 0 rgb(84 94 111 / 30%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.success {
  color: green;
}

.sign-btn {
  border-bottom: 3px solid #0000002f !important;
  background-color: #5C6BC0;
  font-size: 15px;
  padding: 10px 20px;
  border: none;
  font-weight: 500;
  margin-top: 10px;
  border-radius: 5px;
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: center;
  color: white;
  font-family: 'Segoe UI', sans-serif;
  transition-duration: 0.2s;
  cursor: pointer;
}

.lang-btn {
  padding: 8px 15px;
  background: white;
  border: 1px solid #ebedf3;
  border-radius: 4px;
  font-family: 'Segoe UI', sans-serif;
  font-weight: 700;
  margin-right: 5px;
  cursor: pointer;
}

.sign-btn:hover {
  transition-duration: 0.2s;
  transform: translateY(-2px);
}

.sign-btn:disabled {
  transition-duration: 0.2s;
  background-color: #cbcedf;
  color: #666;
  transform: none;
}

body {
  background-color: #ddd;
}

input, button {
  outline: none;
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    width: 70px;
    height: auto;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 3px 10px;
  position: relative;
  font-size: 4px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.tablinks {
    background-color: inherit;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
}

    /* Change background color of buttons on hover */
.tablinks:hover {
    background-color: #ddd;
    border-bottom: 3px solid #bbb;
}

    /* Create an active/current tablink class */
.tablinks.active {
    background-color: #ccc;
    border-bottom: 3px solid #bbb;
}