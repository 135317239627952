body {
  background-color: #ddd;  
}

input, button {
  outline: none;
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    width: 70px;
    height: auto;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 15px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 3px 10px;
  position: relative;
  font-size: 4px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}